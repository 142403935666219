const countryTranslations = {
  ad: "Andorra",
  ae: "Spojené arabské emiráty",
  af: "Afganistan",
  ag: "Antigua a Barbuda",
  ai: "Anguilla",
  al: "Albánsko",
  am: "Arménsko",
  ao: "Angola",
  ar: "Argentína",
  as: "Americká Samoa",
  at: "Rakúsko",
  au: "Austrália",
  aw: "Aruba",
  ax: "Alandy",
  az: "Azerbajdžan",
  ba: "Bosna a Hercegovina",
  bb: "Barbados",
  bd: "Bangladéš",
  be: "Belgicko",
  bf: "Burkina Faso",
  bg: "Bulharsko",
  bh: "Bahrajn",
  bi: "Burundi",
  bj: "Benin",
  bl: "Svätý Bartolomej",
  bm: "Bermudy",
  bn: "Brunej",
  bo: "Bolívia",
  bq: "Karibské Holandsko",
  br: "Brazília",
  bs: "Bahamy",
  bt: "Bhután",
  bw: "Botswana",
  by: "Bielorusko",
  bz: "Belize",
  ca: "Kanada",
  cc: "Kokosové ostrovy",
  cd: "Konžská demokratická republika",
  cf: "Stredoafrická republika",
  cg: "Konžská republika",
  ch: "Švajčiarsko",
  ci: "Pobrežie Slonoviny",
  ck: "Cookove ostrovy",
  cl: "Čile",
  cm: "Kamerun",
  cn: "Čína",
  co: "Kolumbia",
  cr: "Kostarika",
  cu: "Kuba",
  cv: "Kapverdy",
  cw: "Curaçao",
  cx: "Vianočný ostrov",
  cy: "Cyprus",
  cz: "Česko",
  de: "Nemecko",
  dj: "Džibutsko",
  dk: "Dánsko",
  dm: "Dominika",
  do: "Dominikánska republika",
  dz: "Alžírsko",
  ec: "Ekvádor",
  ee: "Estónsko",
  eg: "Egypt",
  eh: "Západná Sahara",
  er: "Eritrea",
  es: "Španielsko",
  et: "Etiópia",
  fi: "Fínsko",
  fj: "Fidži",
  fk: "Falklandy",
  fm: "Mikronézia",
  fo: "Faerské ostrovy",
  fr: "Francúzsko",
  ga: "Gabon",
  gb: "Spojené kráľovstvo",
  gd: "Grenada",
  ge: "Gruzínsko",
  gf: "Francúzska Guyana",
  gg: "Guernsey",
  gh: "Ghana",
  gi: "Gibraltár",
  gl: "Grónsko",
  gm: "Gambia",
  gn: "Guinea",
  gp: "Guadeloupe",
  gq: "Rovníková Guinea",
  gr: "Grécko",
  gt: "Guatemala",
  gu: "Guam",
  gw: "Guinea-Bissau",
  gy: "Guyana",
  hk: "Hongkong – OAO Číny",
  hn: "Honduras",
  hr: "Chorvátsko",
  ht: "Haiti",
  hu: "Maďarsko",
  id: "Indonézia",
  ie: "Írsko",
  il: "Izrael",
  im: "Ostrov Man",
  in: "India",
  io: "Britské indickooceánske územie",
  iq: "Irak",
  ir: "Irán",
  is: "Island",
  it: "Taliansko",
  je: "Jersey",
  jm: "Jamajka",
  jo: "Jordánsko",
  jp: "Japonsko",
  ke: "Keňa",
  kg: "Kirgizsko",
  kh: "Kambodža",
  ki: "Kiribati",
  km: "Komory",
  kn: "Svätý Krištof a Nevis",
  kp: "Severná Kórea",
  kr: "Južná Kórea",
  kw: "Kuvajt",
  ky: "Kajmanie ostrovy",
  kz: "Kazachstan",
  la: "Laos",
  lb: "Libanon",
  lc: "Svätá Lucia",
  li: "Lichtenštajnsko",
  lk: "Srí Lanka",
  lr: "Libéria",
  ls: "Lesotho",
  lt: "Litva",
  lu: "Luxembursko",
  lv: "Lotyšsko",
  ly: "Líbya",
  ma: "Maroko",
  mc: "Monako",
  md: "Moldavsko",
  me: "Čierna Hora",
  mf: "Svätý Martin (fr.)",
  mg: "Madagaskar",
  mh: "Marshallove ostrovy",
  mk: "Severné Macedónsko",
  ml: "Mali",
  mm: "Mjanmarsko",
  mn: "Mongolsko",
  mo: "Macao – OAO Číny",
  mp: "Severné Mariány",
  mq: "Martinik",
  mr: "Mauritánia",
  ms: "Montserrat",
  mt: "Malta",
  mu: "Maurícius",
  mv: "Maldivy",
  mw: "Malawi",
  mx: "Mexiko",
  my: "Malajzia",
  mz: "Mozambik",
  na: "Namíbia",
  nc: "Nová Kaledónia",
  ne: "Niger",
  nf: "Norfolk",
  ng: "Nigéria",
  ni: "Nikaragua",
  nl: "Holandsko",
  no: "Nórsko",
  np: "Nepál",
  nr: "Nauru",
  nu: "Niue",
  nz: "Nový Zéland",
  om: "Omán",
  pa: "Panama",
  pe: "Peru",
  pf: "Francúzska Polynézia",
  pg: "Papua-Nová Guinea",
  ph: "Filipíny",
  pk: "Pakistan",
  pl: "Poľsko",
  pm: "Saint Pierre a Miquelon",
  pr: "Portoriko",
  ps: "Palestínske územia",
  pt: "Portugalsko",
  pw: "Palau",
  py: "Paraguaj",
  qa: "Katar",
  re: "Réunion",
  ro: "Rumunsko",
  rs: "Srbsko",
  ru: "Rusko",
  rw: "Rwanda",
  sa: "Saudská Arábia",
  sb: "Šalamúnove ostrovy",
  sc: "Seychely",
  sd: "Sudán",
  se: "Švédsko",
  sg: "Singapur",
  sh: "Svätá Helena",
  si: "Slovinsko",
  sj: "Svalbard a Jan Mayen",
  sk: "Slovensko",
  sl: "Sierra Leone",
  sm: "San Maríno",
  sn: "Senegal",
  so: "Somálsko",
  sr: "Surinam",
  ss: "Južný Sudán",
  st: "Svätý Tomáš a Princov ostrov",
  sv: "Salvádor",
  sx: "Svätý Martin (hol.)",
  sy: "Sýria",
  sz: "Eswatini",
  tc: "Turks a Caicos",
  td: "Čad",
  tg: "Togo",
  th: "Thajsko",
  tj: "Tadžikistan",
  tk: "Tokelau",
  tl: "Východný Timor",
  tm: "Turkménsko",
  tn: "Tunisko",
  to: "Tonga",
  tr: "Turecko",
  tt: "Trinidad a Tobago",
  tv: "Tuvalu",
  tw: "Taiwan",
  tz: "Tanzánia",
  ua: "Ukrajina",
  ug: "Uganda",
  us: "Spojené štáty",
  uy: "Uruguaj",
  uz: "Uzbekistan",
  va: "Vatikán",
  vc: "Svätý Vincent a Grenadíny",
  ve: "Venezuela",
  vg: "Britské Panenské ostrovy",
  vi: "Americké Panenské ostrovy",
  vn: "Vietnam",
  vu: "Vanuatu",
  wf: "Wallis a Futuna",
  ws: "Samoa",
  ye: "Jemen",
  yt: "Mayotte",
  za: "Južná Afrika",
  zm: "Zambia",
  zw: "Zimbabwe"
};
export default countryTranslations;