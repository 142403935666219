const countryTranslations = {
  ad: "آندورا",
  ae: "امارات متحدهٔ عربی",
  af: "افغانستان",
  ag: "آنتیگوا و باربودا",
  ai: "آنگویلا",
  al: "آلبانی",
  am: "ارمنستان",
  ao: "آنگولا",
  ar: "آرژانتین",
  as: "ساموآی امریکا",
  at: "اتریش",
  au: "استرالیا",
  aw: "آروبا",
  ax: "جزایر آلاند",
  az: "جمهوری آذربایجان",
  ba: "بوسنی و هرزگوین",
  bb: "باربادوس",
  bd: "بنگلادش",
  be: "بلژیک",
  bf: "بورکینافاسو",
  bg: "بلغارستان",
  bh: "بحرین",
  bi: "بوروندی",
  bj: "بنین",
  bl: "سن بارتلمی",
  bm: "برمودا",
  bn: "برونئی",
  bo: "بولیوی",
  bq: "جزایر کارائیب هلند",
  br: "برزیل",
  bs: "باهاما",
  bt: "بوتان",
  bw: "بوتسوانا",
  by: "بلاروس",
  bz: "بلیز",
  ca: "کانادا",
  cc: "جزایر کوکوس",
  cd: "کنگو - کینشاسا",
  cf: "جمهوری افریقای مرکزی",
  cg: "کنگو - برازویل",
  ch: "سوئیس",
  ci: "ساحل عاج",
  ck: "جزایر کوک",
  cl: "شیلی",
  cm: "کامرون",
  cn: "چین",
  co: "کلمبیا",
  cr: "کاستاریکا",
  cu: "کوبا",
  cv: "کیپ‌ورد",
  cw: "کوراسائو",
  cx: "جزیرهٔ کریسمس",
  cy: "قبرس",
  cz: "چک",
  de: "آلمان",
  dj: "جیبوتی",
  dk: "دانمارک",
  dm: "دومینیکا",
  do: "جمهوری دومینیکن",
  dz: "الجزایر",
  ec: "اکوادور",
  ee: "استونی",
  eg: "مصر",
  eh: "صحرای غربی",
  er: "اریتره",
  es: "اسپانیا",
  et: "اتیوپی",
  fi: "فنلاند",
  fj: "فیجی",
  fk: "جزایر فالکلند",
  fm: "میکرونزی",
  fo: "جزایر فارو",
  fr: "فرانسه",
  ga: "گابن",
  gb: "بریتانیا",
  gd: "گرنادا",
  ge: "گرجستان",
  gf: "گویان فرانسه",
  gg: "گرنزی",
  gh: "غنا",
  gi: "جبل‌الطارق",
  gl: "گرینلند",
  gm: "گامبیا",
  gn: "گینه",
  gp: "گوادلوپ",
  gq: "گینهٔ استوایی",
  gr: "یونان",
  gt: "گواتمالا",
  gu: "گوام",
  gw: "گینهٔ بیسائو",
  gy: "گویان",
  hk: "هنگ‌کنگ، منطقهٔ ویژهٔ اداری چین",
  hn: "هندوراس",
  hr: "کرواسی",
  ht: "هائیتی",
  hu: "مجارستان",
  id: "اندونزی",
  ie: "ایرلند",
  il: "اسرائیل",
  im: "جزیرهٔ من",
  in: "هند",
  io: "قلمرو بریتانیا در اقیانوس هند",
  iq: "عراق",
  ir: "ایران",
  is: "ایسلند",
  it: "ایتالیا",
  je: "جرزی",
  jm: "جامائیکا",
  jo: "اردن",
  jp: "ژاپن",
  ke: "کنیا",
  kg: "قرقیزستان",
  kh: "کامبوج",
  ki: "کیریباتی",
  km: "کومور",
  kn: "سنت کیتس و نویس",
  kp: "کرهٔ شمالی",
  kr: "کرهٔ جنوبی",
  kw: "کویت",
  ky: "جزایر کِیمن",
  kz: "قزاقستان",
  la: "لائوس",
  lb: "لبنان",
  lc: "سنت لوسیا",
  li: "لیختن‌اشتاین",
  lk: "سری‌لانکا",
  lr: "لیبریا",
  ls: "لسوتو",
  lt: "لیتوانی",
  lu: "لوکزامبورگ",
  lv: "لتونی",
  ly: "لیبی",
  ma: "مراکش",
  mc: "موناکو",
  md: "مولداوی",
  me: "مونته‌نگرو",
  mf: "سنت مارتین",
  mg: "ماداگاسکار",
  mh: "جزایر مارشال",
  mk: "مقدونیهٔ شمالی",
  ml: "مالی",
  mm: "میانمار (برمه)",
  mn: "مغولستان",
  mo: "ماکائو، منطقهٔ ویژهٔ اداری چین",
  mp: "جزایر ماریانای شمالی",
  mq: "مارتینیک",
  mr: "موریتانی",
  ms: "مونت‌سرات",
  mt: "مالت",
  mu: "موریس",
  mv: "مالدیو",
  mw: "مالاوی",
  mx: "مکزیک",
  my: "مالزی",
  mz: "موزامبیک",
  na: "نامیبیا",
  nc: "کالدونیای جدید",
  ne: "نیجر",
  nf: "جزیرهٔ نورفولک",
  ng: "نیجریه",
  ni: "نیکاراگوئه",
  nl: "هلند",
  no: "نروژ",
  np: "نپال",
  nr: "نائورو",
  nu: "نیوئه",
  nz: "نیوزیلند",
  om: "عمان",
  pa: "پاناما",
  pe: "پرو",
  pf: "پلی‌نزی فرانسه",
  pg: "پاپوا گینهٔ نو",
  ph: "فیلیپین",
  pk: "پاکستان",
  pl: "لهستان",
  pm: "سن پیر و میکلن",
  pr: "پورتوریکو",
  ps: "سرزمین‌های فلسطینی",
  pt: "پرتغال",
  pw: "پالائو",
  py: "پاراگوئه",
  qa: "قطر",
  re: "رئونیون",
  ro: "رومانی",
  rs: "صربستان",
  ru: "روسیه",
  rw: "رواندا",
  sa: "عربستان سعودی",
  sb: "جزایر سلیمان",
  sc: "سیشل",
  sd: "سودان",
  se: "سوئد",
  sg: "سنگاپور",
  sh: "سنت هلن",
  si: "اسلوونی",
  sj: "سوالبارد و یان ماین",
  sk: "اسلواکی",
  sl: "سیرالئون",
  sm: "سان‌مارینو",
  sn: "سنگال",
  so: "سومالی",
  sr: "سورینام",
  ss: "سودان جنوبی",
  st: "سائوتومه و پرینسیپ",
  sv: "السالوادور",
  sx: "سنت مارتن",
  sy: "سوریه",
  sz: "اسواتینی",
  tc: "جزایر تورکس و کایکوس",
  td: "چاد",
  tg: "توگو",
  th: "تایلند",
  tj: "تاجیکستان",
  tk: "توکلائو",
  tl: "تیمور-لسته",
  tm: "ترکمنستان",
  tn: "تونس",
  to: "تونگا",
  tr: "ترکیه",
  tt: "ترینیداد و توباگو",
  tv: "تووالو",
  tw: "تایوان",
  tz: "تانزانیا",
  ua: "اوکراین",
  ug: "اوگاندا",
  us: "ایالات متحده",
  uy: "اروگوئه",
  uz: "ازبکستان",
  va: "واتیکان",
  vc: "سنت وینسنت و گرنادین",
  ve: "ونزوئلا",
  vg: "جزایر ویرجین بریتانیا",
  vi: "جزایر ویرجین ایالات متحده",
  vn: "ویتنام",
  vu: "وانواتو",
  wf: "والیس و فوتونا",
  ws: "ساموآ",
  ye: "یمن",
  yt: "مایوت",
  za: "افریقای جنوبی",
  zm: "زامبیا",
  zw: "زیمبابوه"
};
export default countryTranslations;