import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { catchError, first, map, switchMap } from 'rxjs/operators'
import { AuthenticationService, AutoLogoutTimerService, IUserDetail, LoginStorageTokens, RefreshService, UserDetailService } from "@certiport/login-library";
import { EditViewSidesheetService } from "../../services/edit-view-sidesheet.service";
import { LanguagePickerService, SessionStorageService, UserService } from "certiport-layout-library";
import { TranslocoService } from "@jsverse/transloco";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { SettingsCacheService } from "../../services/settings-cache.service";
import { ConfigService } from "src/app/services/config-service";
import { LoginService } from "src/app/services/login.service";


@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  constructor(private router: Router,
    private refreshService: RefreshService,
    private authenticationService: AuthenticationService,
    private autoLogoutTimerService: AutoLogoutTimerService,
    private userDetailService: UserDetailService,
    private editViewSidesheetService: EditViewSidesheetService,
    private languagePickerService: LanguagePickerService,
    private transloco: TranslocoService,
    private sessionStorage: SessionStorageService,
    private localStorageService: LocalStorageService,
    private settingsCacheService: SettingsCacheService,
    private configService: ConfigService,
    private userService: UserService,
    private window: Window,
    private loginService: LoginService
  ) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return this.settingsCacheService.load().pipe(
      switchMap(() => {
        if (this.settingsCacheService.iesFeatureEnabled) {
          return this.canActivate_IES();
        }
        else {
          return this.canActivate_NonIES(route, state);
        }
      }),
      catchError(error => {
        return of(false);
      }));
  }

  canActivate_IES(): Observable<boolean> {
    const registrationUrl = this.configService.iesRegUri;
    const returnUrl = this.configService.iesReturnUri;

    return this.authenticationService.validate(true).pipe(
      first(),
      switchMap(response => {
        if (response === 206) {
          this.window.location.href = `${registrationUrl}&returnUrl=${encodeURIComponent(returnUrl,)}`;
          return of(false);
        } else if (response >= 200 && response < 300) {
          this.loginService.afterSucessfulIESAuthentication();
          return of(true);
        } else {
          this.authenticationService.logout();
          this.loginService.redirectToIESLogin();
          return of(false);
        }
      })
    );
  }

  private canActivate_NonIES(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    const sid = route.queryParamMap.get('sid');
    const langCode = route.queryParamMap.get('languagecode');
    if (sid) {
      if (langCode) {
        this.languagePickerService.setLocale(langCode.toLocaleLowerCase());
        this.transloco.setActiveLang(langCode.toLocaleLowerCase());
        this.sessionStorage.setItem('languageCode', langCode.toLocaleLowerCase());
      }
      else {
        this.languagePickerService.setLocale('enu');
        this.transloco.setActiveLang('enu');
      }
      return this.loginWithSid(sid, route);
    }

    
    if (!this.localStorageService.getItem(LoginStorageTokens.Refresh)) {
      this.router.navigate(['/login']);
      return of(false);
    }

    return this.refreshService.RefreshToken().pipe(
      map(() => {
        this.loginService.setUserName();
        return true;
      }),
      catchError((e, caught) => {
        localStorage.clear();
        this.userService.setUserName('');
        this.userDetailService.setUserDetail(this.buildEmptyuserDetail())
        this.settingsCacheService.userLoggedIn = false;
        this.logout();
        this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
        return of(false);
      })
    )
  }

  private logout() {
    this.autoLogoutTimerService.stop();
    this.authenticationService.logout().pipe(
      map(() => {
        this.editViewSidesheetService.closeSideSheets();
        this.router.navigate(['/login']);
      }),
      catchError(error => {
        return of();
      }))
      .subscribe();
  }

  private buildEmptyuserDetail(): IUserDetail{
    return {
      userName: '',
      userId: 0,
      userRoles: [],
      loginExpiry: new Date(0)
    }
  }

  loginWithSid(sid: string, route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authenticationService.AuthenticateWithSid(sid).pipe(
      map(
        result => {
          if (result?.body?.RefreshToken) {
            localStorage.setItem(LoginStorageTokens.Refresh, result.body?.RefreshToken!)
            this.router.navigate([route.routeConfig?.path], {
              queryParams: { 'sid': null },
              queryParamsHandling: 'merge'
            });
            return true;
          }
          this.router.navigate(['login']);
          return false;
        }),
      catchError(() => {
        this.router.navigate(['login']);
        return of(false);
      }))
  }
}
