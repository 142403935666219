const interfaceTranslations = {
  selectedCountryAriaLabel: "Kiválasztott ország",
  noCountrySelected: "Nincs ország kiválasztva",
  countryListAriaLabel: "Országok listája",
  searchPlaceholder: "Keresés",
  zeroSearchResults: "Nincs találat",
  oneSearchResult: "1 találat",
  multipleSearchResults: "${count} találat",
  // additional countries (not supported by country-list library)
  ac: "Ascension-sziget",
  xk: "Koszovó"
};
export default interfaceTranslations;