import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";

import { IExam } from "../models/exam";
import { IProgram } from "../models/program";
import { IExamLanguage } from "../models/exam-language";
import { ConfigService } from "./config-service";

@Injectable({
  providedIn: 'root'
})
export class ExamProductService {
  environment: any;
  private deliverySystemID: number = 12;

  constructor(private httpClient: HttpClient, private configService: ConfigService) {    
  }

  getExamLanguages(): Observable<IExamLanguage[]> {
    const url = `${this.configService.examProductServiceUrl}/api/languages`;
    return this.httpClient.get<IExamLanguage[]>(url, { withCredentials: true, headers: { 'cbbEnabledLanguages': 'true' } });
  }

  getExams(examslanguageCode: string): Observable<IExam[]> {
    const url = `${this.configService.examProductServiceUrl}/api/exams?languageCode=${examslanguageCode}&deliverySystemID=${this.deliverySystemID}&compassCloudEnabled=${this.configService.isCompassCloudEnabled}`;
    return this.httpClient.get<IExam[]>(url, { withCredentials: true });
  }

  getPrograms(): Observable<IProgram[]> {
    const url = `${this.configService.examProductServiceUrl}/api/programs`;
    return this.httpClient.get<IProgram[]>(url, { withCredentials: true });
  }

}
