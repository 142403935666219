const countryTranslations = {
  ad: "أندورا",
  ae: "الإمارات العربية المتحدة",
  af: "أفغانستان",
  ag: "أنتيغوا وبربودا",
  ai: "أنغويلا",
  al: "ألبانيا",
  am: "أرمينيا",
  ao: "أنغولا",
  ar: "الأرجنتين",
  as: "ساموا الأمريكية",
  at: "النمسا",
  au: "أستراليا",
  aw: "أروبا",
  ax: "جزر آلاند",
  az: "أذربيجان",
  ba: "البوسنة والهرسك",
  bb: "بربادوس",
  bd: "بنغلاديش",
  be: "بلجيكا",
  bf: "بوركينا فاسو",
  bg: "بلغاريا",
  bh: "البحرين",
  bi: "بوروندي",
  bj: "بنين",
  bl: "سان بارتليمي",
  bm: "برمودا",
  bn: "بروناي",
  bo: "بوليفيا",
  bq: "هولندا الكاريبية",
  br: "البرازيل",
  bs: "جزر البهاما",
  bt: "بوتان",
  bw: "بوتسوانا",
  by: "بيلاروس",
  bz: "بليز",
  ca: "كندا",
  cc: "جزر كوكوس (كيلينغ)",
  cd: "الكونغو - كينشاسا",
  cf: "جمهورية أفريقيا الوسطى",
  cg: "الكونغو - برازافيل",
  ch: "سويسرا",
  ci: "ساحل العاج",
  ck: "جزر كوك",
  cl: "تشيلي",
  cm: "الكاميرون",
  cn: "الصين",
  co: "كولومبيا",
  cr: "كوستاريكا",
  cu: "كوبا",
  cv: "الرأس الأخضر",
  cw: "كوراساو",
  cx: "جزيرة كريسماس",
  cy: "قبرص",
  cz: "التشيك",
  de: "ألمانيا",
  dj: "جيبوتي",
  dk: "الدانمرك",
  dm: "دومينيكا",
  do: "جمهورية الدومينيكان",
  dz: "الجزائر",
  ec: "الإكوادور",
  ee: "إستونيا",
  eg: "مصر",
  eh: "الصحراء الغربية",
  er: "إريتريا",
  es: "إسبانيا",
  et: "إثيوبيا",
  fi: "فنلندا",
  fj: "فيجي",
  fk: "جزر فوكلاند",
  fm: "ميكرونيزيا",
  fo: "جزر فارو",
  fr: "فرنسا",
  ga: "الغابون",
  gb: "المملكة المتحدة",
  gd: "غرينادا",
  ge: "جورجيا",
  gf: "غويانا الفرنسية",
  gg: "غيرنزي",
  gh: "غانا",
  gi: "جبل طارق",
  gl: "غرينلاند",
  gm: "غامبيا",
  gn: "غينيا",
  gp: "غوادلوب",
  gq: "غينيا الاستوائية",
  gr: "اليونان",
  gt: "غواتيمالا",
  gu: "غوام",
  gw: "غينيا بيساو",
  gy: "غيانا",
  hk: "هونغ كونغ الصينية (منطقة إدارية خاصة)",
  hn: "هندوراس",
  hr: "كرواتيا",
  ht: "هايتي",
  hu: "هنغاريا",
  id: "إندونيسيا",
  ie: "أيرلندا",
  il: "إسرائيل",
  im: "جزيرة مان",
  in: "الهند",
  io: "الإقليم البريطاني في المحيط الهندي",
  iq: "العراق",
  ir: "إيران",
  is: "آيسلندا",
  it: "إيطاليا",
  je: "جيرسي",
  jm: "جامايكا",
  jo: "الأردن",
  jp: "اليابان",
  ke: "كينيا",
  kg: "قيرغيزستان",
  kh: "كمبوديا",
  ki: "كيريباتي",
  km: "جزر القمر",
  kn: "سانت كيتس ونيفيس",
  kp: "كوريا الشمالية",
  kr: "كوريا الجنوبية",
  kw: "الكويت",
  ky: "جزر كايمان",
  kz: "كازاخستان",
  la: "لاوس",
  lb: "لبنان",
  lc: "سانت لوسيا",
  li: "ليختنشتاين",
  lk: "سريلانكا",
  lr: "ليبيريا",
  ls: "ليسوتو",
  lt: "ليتوانيا",
  lu: "لوكسمبورغ",
  lv: "لاتفيا",
  ly: "ليبيا",
  ma: "المغرب",
  mc: "موناكو",
  md: "مولدوفا",
  me: "الجبل الأسود",
  mf: "سان مارتن",
  mg: "مدغشقر",
  mh: "جزر مارشال",
  mk: "مقدونيا الشمالية",
  ml: "مالي",
  mm: "ميانمار (بورما)",
  mn: "منغوليا",
  mo: "منطقة ماكاو الإدارية الخاصة",
  mp: "جزر ماريانا الشمالية",
  mq: "جزر المارتينيك",
  mr: "موريتانيا",
  ms: "مونتسرات",
  mt: "مالطا",
  mu: "موريشيوس",
  mv: "جزر المالديف",
  mw: "ملاوي",
  mx: "المكسيك",
  my: "ماليزيا",
  mz: "موزمبيق",
  na: "ناميبيا",
  nc: "كاليدونيا الجديدة",
  ne: "النيجر",
  nf: "جزيرة نورفولك",
  ng: "نيجيريا",
  ni: "نيكاراغوا",
  nl: "هولندا",
  no: "النرويج",
  np: "نيبال",
  nr: "ناورو",
  nu: "نيوي",
  nz: "نيوزيلندا",
  om: "عُمان",
  pa: "بنما",
  pe: "بيرو",
  pf: "بولينيزيا الفرنسية",
  pg: "بابوا غينيا الجديدة",
  ph: "الفلبين",
  pk: "باكستان",
  pl: "بولندا",
  pm: "سان بيير ومكويلون",
  pr: "بورتوريكو",
  ps: "الأراضي الفلسطينية",
  pt: "البرتغال",
  pw: "بالاو",
  py: "باراغواي",
  qa: "قطر",
  re: "روينيون",
  ro: "رومانيا",
  rs: "صربيا",
  ru: "روسيا",
  rw: "رواندا",
  sa: "المملكة العربية السعودية",
  sb: "جزر سليمان",
  sc: "سيشل",
  sd: "السودان",
  se: "السويد",
  sg: "سنغافورة",
  sh: "سانت هيلينا",
  si: "سلوفينيا",
  sj: "سفالبارد وجان ماين",
  sk: "سلوفاكيا",
  sl: "سيراليون",
  sm: "سان مارينو",
  sn: "السنغال",
  so: "الصومال",
  sr: "سورينام",
  ss: "جنوب السودان",
  st: "ساو تومي وبرينسيبي",
  sv: "السلفادور",
  sx: "سانت مارتن",
  sy: "سوريا",
  sz: "إسواتيني",
  tc: "جزر توركس وكايكوس",
  td: "تشاد",
  tg: "توغو",
  th: "تايلاند",
  tj: "طاجيكستان",
  tk: "توكيلو",
  tl: "تيمور - ليشتي",
  tm: "تركمانستان",
  tn: "تونس",
  to: "تونغا",
  tr: "تركيا",
  tt: "ترينيداد وتوباغو",
  tv: "توفالو",
  tw: "تايوان",
  tz: "تنزانيا",
  ua: "أوكرانيا",
  ug: "أوغندا",
  us: "الولايات المتحدة",
  uy: "أورغواي",
  uz: "أوزبكستان",
  va: "الفاتيكان",
  vc: "سانت فنسنت وجزر غرينادين",
  ve: "فنزويلا",
  vg: "جزر فيرجن البريطانية",
  vi: "جزر فيرجن التابعة للولايات المتحدة",
  vn: "فيتنام",
  vu: "فانواتو",
  wf: "جزر والس وفوتونا",
  ws: "ساموا",
  ye: "اليمن",
  yt: "مايوت",
  za: "جنوب أفريقيا",
  zm: "زامبيا",
  zw: "زيمبابوي"
};
export default countryTranslations;