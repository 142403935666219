import { Injectable } from '@angular/core';
import { SessionTypes, SessionType } from 'src/app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class SessionTypeService {
    private sessionType: SessionType;

    constructor() {
        this.sessionType = SessionTypes.Local;
    }

    getSessionType(): SessionType {
        return this.sessionType;
    }

    setSessionType(type: SessionType): void {
        if (Object.values(SessionTypes).includes(type)) {
            this.sessionType = type;
        } else {
            throw new Error(`Invalid session type: ${type}`);
        }
    }
}