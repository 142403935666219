import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { IAppConfig } from "../models/app-config";



@Injectable({
  providedIn: 'root'
})

export class ConfigService {
  private configuration!: IAppConfig;
  private loaded: boolean = false;
  public dashBoardURL: string = "";
  public helpAndSupportURL: string = "";
  public webxChatURL: string = "";
  public authenticationServiceUrl: string = "";
  public schedulingServiceURL: string = "";
  public testCenterServiceURL: string = "";
  public portalRootUrl: string = "";
  public ProctorUIUrl: string = "";
  public examProductServiceUrl: string = "";
  public paymentServiceUrl: string = "";
  public userManagementServiceUrl: string = "";
  public translocoLanguageImportPath: string = "";
  public autoLogoutWarningWindowMinutes: number = 0;
  public autoLogoutTickInterval: number = 0;
  public RefreshDelayInMilliseconds: number = 0;
  public RefreshBlackoutTimeBeforeWarning: number = 0;
  public showCookiePreferencesLink: boolean = true;
  public showLogoutTimer: boolean = false
  public refreshTokenPriorInMinutes: number = 0;
  public appInsightsInstrumentationKey: string = '';
  public accountRecoveryUrl: string = '';
  public userAssignedVoucherInfoUrl: string = '';
  public centerAssignedVoucherInfoUrl: string = '';
  public iesBaseLoginUri: string = '';
  public iesSubLoginUri: string = '';
  public iesSSOUri: string = '';
  public iesRegUri: string = '';
  public iesReturnUri: string = '';
  public iesBaseUrl: string = '';
 
  public isCompassCloudEnabled: boolean = false;
  constructor(private httpClient: HttpClient) { }

  setConfig(): Observable<IAppConfig> {
    return this.httpClient.get<IAppConfig>('./assets/app-config.json').pipe(
      map((data: IAppConfig) => {
        this.configuration = data;
        this.loaded = true;
        this.setValues();
        return data;
      })
    );
  }

  private setValues() {
    this.dashBoardURL = this.readConfig().dashBoardURL;
    this.helpAndSupportURL = this.readConfig().helpAndSupportURL;
    this.webxChatURL = this.readConfig().webxChatURL
    this.authenticationServiceUrl = this.readConfig().authenticationServiceUrl;
    this.schedulingServiceURL = this.readConfig().schedulingServiceURL;
    this.testCenterServiceURL = this.readConfig().testCenterServiceURL;
    this.portalRootUrl = this.readConfig().portalRootUrl;
    this.ProctorUIUrl = this.readConfig().ProctorUIUrl;
    this.examProductServiceUrl = this.readConfig().examProductServiceUrl;
    this.paymentServiceUrl = this.readConfig().paymentServiceUrl;
    this.userManagementServiceUrl = this.readConfig().userManagementServiceUrl;
    this.translocoLanguageImportPath = this.readConfig().translocoLanguageImportPath
    this.autoLogoutWarningWindowMinutes = this.readConfig().autoLogoutWarningWindowMinutes;
    this.autoLogoutTickInterval = this.readConfig().autoLogoutTickInterval;
    this.RefreshDelayInMilliseconds = this.readConfig().RefreshDelayInMilliseconds;
    this.RefreshBlackoutTimeBeforeWarning = this.readConfig().RefreshBlackoutTimeBeforeWarning;
    this.showCookiePreferencesLink = this.readConfig().showCookiePreferencesLink;
    this.showLogoutTimer = this.readConfig().showLogoutTimer;
    this.refreshTokenPriorInMinutes = this.readConfig().refreshTokenPriorInMinutes;
    this.appInsightsInstrumentationKey = this.readConfig().appInsightsInstrumentationKey;
    this.accountRecoveryUrl = this.readConfig().accountRecoveryUrl;
    this.userAssignedVoucherInfoUrl = this.readConfig().userAssignedVoucherInfoUrl;
    this.centerAssignedVoucherInfoUrl = this.readConfig().centerAssignedVoucherInfoUrl;
    this.iesBaseLoginUri = this.readConfig().iesBaseLoginUri;
    this.iesSubLoginUri = this.readConfig().iesSubLoginUri;
    this.iesSSOUri = this.readConfig().iesSSOUri;
    this.iesRegUri = this.readConfig().iesRegUri;
    this.iesReturnUri = this.readConfig().iesReturnUri;
    this.iesBaseUrl = this.readConfig().iesBaseUrl;
    this.isCompassCloudEnabled = this.readConfig().isCompassCloudEnabled;
  }
  private readConfig(): IAppConfig {
    if (!this.loaded)
      throw Error("App configs are not loaded.");

    return this.configuration;
  }
}
