import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { TranslocoService } from '@jsverse/transloco';
import { SessionTypeService } from 'src/app/services/session-type.service';
import { SessionTypes, SessionType } from 'src/app/app.constants';

@Component({
  selector: 'app-select-exam-type-dialog',
  templateUrl: './select-exam-type-dialog.component.html',
  styleUrls: ['./select-exam-type-dialog.component.scss']
})
export class SelectExamTypeDialogComponent {
  SessionTypes = SessionTypes;

  public readonly icons = {
    faXmark
  };

  constructor(
    public t: TranslocoService, // How come private is working at other places?
    public dialogRef: MatDialogRef<SelectExamTypeDialogComponent>,
    public sessionTypeService: SessionTypeService) {
  }

  onClick(sessionType: SessionType) {
    if (sessionType) {
      try {
        this.sessionTypeService.setSessionType(sessionType);
        this.dialogRef.close(sessionType);
      } catch (error) {
        console.error(error);      
      }
    }
  }
}
