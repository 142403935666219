
export interface IUser {
  userID: number;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  phone: string;
  roles: IRole[]
}

export interface IRole {
  roleID: number;
  roleName: string;
}

// for get test center info
export interface ITestCenter {
  testCenterID: number;
  testCenterName: string;
  certiportID: string;
  testCenterStatusID: number;
  testCenterTypeID: number;
  hasUserLicenses: boolean;
}

// for getting all test centers user is part of
export interface IUserTestCenter {
  testCenterID: number;
  testCenterName: string;
  certiportID: string;
  ExcludeFromEFH: boolean;
  roles: IRole[]
}

// exam group info
export interface IExamGroup {
  examGroupID: number;
  examGroupName: string;
  exam: IExamGroupExam;
}

// exam group's exam
export interface IExamGroupExam {
  examSeriesCode: string;
  languageCode: string;
  examName: string;
  examDuration: number;
  programId: number;
}

export enum ExamGroupErrorCodes  {
  'NotAssociated' = 16013, 'NotFound' = 16014, 'TestCenterNotFound' = 16015
}
