const interfaceTranslations = {
  selectedCountryAriaLabel: "所选国家",
  noCountrySelected: "未选择国家/地区",
  countryListAriaLabel: "国家名单",
  searchPlaceholder: "搜索",
  zeroSearchResults: "未找到结果",
  oneSearchResult: "找到 1 个结果",
  multipleSearchResults: "找到 ${count} 个结果",
  // additional countries (not supported by country-list library)
  ac: "阿森松岛",
  xk: "科索沃"
};
export default interfaceTranslations;