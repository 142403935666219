const countryTranslations = {
  ad: "Andorra",
  ae: "Spojené arabské emiráty",
  af: "Afghánistán",
  ag: "Antigua a Barbuda",
  ai: "Anguilla",
  al: "Albánie",
  am: "Arménie",
  ao: "Angola",
  ar: "Argentina",
  as: "Americká Samoa",
  at: "Rakousko",
  au: "Austrálie",
  aw: "Aruba",
  ax: "Ålandy",
  az: "Ázerbájdžán",
  ba: "Bosna a Hercegovina",
  bb: "Barbados",
  bd: "Bangladéš",
  be: "Belgie",
  bf: "Burkina Faso",
  bg: "Bulharsko",
  bh: "Bahrajn",
  bi: "Burundi",
  bj: "Benin",
  bl: "Svatý Bartoloměj",
  bm: "Bermudy",
  bn: "Brunej",
  bo: "Bolívie",
  bq: "Karibské Nizozemsko",
  br: "Brazílie",
  bs: "Bahamy",
  bt: "Bhútán",
  bw: "Botswana",
  by: "Bělorusko",
  bz: "Belize",
  ca: "Kanada",
  cc: "Kokosové ostrovy",
  cd: "Kongo – Kinshasa",
  cf: "Středoafrická republika",
  cg: "Kongo – Brazzaville",
  ch: "Švýcarsko",
  ci: "Pobřeží slonoviny",
  ck: "Cookovy ostrovy",
  cl: "Chile",
  cm: "Kamerun",
  cn: "Čína",
  co: "Kolumbie",
  cr: "Kostarika",
  cu: "Kuba",
  cv: "Kapverdy",
  cw: "Curaçao",
  cx: "Vánoční ostrov",
  cy: "Kypr",
  cz: "Česko",
  de: "Německo",
  dj: "Džibutsko",
  dk: "Dánsko",
  dm: "Dominika",
  do: "Dominikánská republika",
  dz: "Alžírsko",
  ec: "Ekvádor",
  ee: "Estonsko",
  eg: "Egypt",
  eh: "Západní Sahara",
  er: "Eritrea",
  es: "Španělsko",
  et: "Etiopie",
  fi: "Finsko",
  fj: "Fidži",
  fk: "Falklandské ostrovy",
  fm: "Mikronésie",
  fo: "Faerské ostrovy",
  fr: "Francie",
  ga: "Gabon",
  gb: "Spojené království",
  gd: "Grenada",
  ge: "Gruzie",
  gf: "Francouzská Guyana",
  gg: "Guernsey",
  gh: "Ghana",
  gi: "Gibraltar",
  gl: "Grónsko",
  gm: "Gambie",
  gn: "Guinea",
  gp: "Guadeloupe",
  gq: "Rovníková Guinea",
  gr: "Řecko",
  gt: "Guatemala",
  gu: "Guam",
  gw: "Guinea-Bissau",
  gy: "Guyana",
  hk: "Hongkong – ZAO Číny",
  hn: "Honduras",
  hr: "Chorvatsko",
  ht: "Haiti",
  hu: "Maďarsko",
  id: "Indonésie",
  ie: "Irsko",
  il: "Izrael",
  im: "Ostrov Man",
  in: "Indie",
  io: "Britské indickooceánské území",
  iq: "Irák",
  ir: "Írán",
  is: "Island",
  it: "Itálie",
  je: "Jersey",
  jm: "Jamajka",
  jo: "Jordánsko",
  jp: "Japonsko",
  ke: "Keňa",
  kg: "Kyrgyzstán",
  kh: "Kambodža",
  ki: "Kiribati",
  km: "Komory",
  kn: "Svatý Kryštof a Nevis",
  kp: "Severní Korea",
  kr: "Jižní Korea",
  kw: "Kuvajt",
  ky: "Kajmanské ostrovy",
  kz: "Kazachstán",
  la: "Laos",
  lb: "Libanon",
  lc: "Svatá Lucie",
  li: "Lichtenštejnsko",
  lk: "Srí Lanka",
  lr: "Libérie",
  ls: "Lesotho",
  lt: "Litva",
  lu: "Lucembursko",
  lv: "Lotyšsko",
  ly: "Libye",
  ma: "Maroko",
  mc: "Monako",
  md: "Moldavsko",
  me: "Černá Hora",
  mf: "Svatý Martin (Francie)",
  mg: "Madagaskar",
  mh: "Marshallovy ostrovy",
  mk: "Severní Makedonie",
  ml: "Mali",
  mm: "Myanmar (Barma)",
  mn: "Mongolsko",
  mo: "Macao – ZAO Číny",
  mp: "Severní Mariany",
  mq: "Martinik",
  mr: "Mauritánie",
  ms: "Montserrat",
  mt: "Malta",
  mu: "Mauricius",
  mv: "Maledivy",
  mw: "Malawi",
  mx: "Mexiko",
  my: "Malajsie",
  mz: "Mosambik",
  na: "Namibie",
  nc: "Nová Kaledonie",
  ne: "Niger",
  nf: "Norfolk",
  ng: "Nigérie",
  ni: "Nikaragua",
  nl: "Nizozemsko",
  no: "Norsko",
  np: "Nepál",
  nr: "Nauru",
  nu: "Niue",
  nz: "Nový Zéland",
  om: "Omán",
  pa: "Panama",
  pe: "Peru",
  pf: "Francouzská Polynésie",
  pg: "Papua-Nová Guinea",
  ph: "Filipíny",
  pk: "Pákistán",
  pl: "Polsko",
  pm: "Saint-Pierre a Miquelon",
  pr: "Portoriko",
  ps: "Palestinská území",
  pt: "Portugalsko",
  pw: "Palau",
  py: "Paraguay",
  qa: "Katar",
  re: "Réunion",
  ro: "Rumunsko",
  rs: "Srbsko",
  ru: "Rusko",
  rw: "Rwanda",
  sa: "Saúdská Arábie",
  sb: "Šalamounovy ostrovy",
  sc: "Seychely",
  sd: "Súdán",
  se: "Švédsko",
  sg: "Singapur",
  sh: "Svatá Helena",
  si: "Slovinsko",
  sj: "Špicberky a Jan Mayen",
  sk: "Slovensko",
  sl: "Sierra Leone",
  sm: "San Marino",
  sn: "Senegal",
  so: "Somálsko",
  sr: "Surinam",
  ss: "Jižní Súdán",
  st: "Svatý Tomáš a Princův ostrov",
  sv: "Salvador",
  sx: "Svatý Martin (Nizozemsko)",
  sy: "Sýrie",
  sz: "Svazijsko",
  tc: "Turks a Caicos",
  td: "Čad",
  tg: "Togo",
  th: "Thajsko",
  tj: "Tádžikistán",
  tk: "Tokelau",
  tl: "Východní Timor",
  tm: "Turkmenistán",
  tn: "Tunisko",
  to: "Tonga",
  tr: "Turecko",
  tt: "Trinidad a Tobago",
  tv: "Tuvalu",
  tw: "Tchaj-wan",
  tz: "Tanzanie",
  ua: "Ukrajina",
  ug: "Uganda",
  us: "Spojené státy",
  uy: "Uruguay",
  uz: "Uzbekistán",
  va: "Vatikán",
  vc: "Svatý Vincenc a Grenadiny",
  ve: "Venezuela",
  vg: "Britské Panenské ostrovy",
  vi: "Americké Panenské ostrovy",
  vn: "Vietnam",
  vu: "Vanuatu",
  wf: "Wallis a Futuna",
  ws: "Samoa",
  ye: "Jemen",
  yt: "Mayotte",
  za: "Jihoafrická republika",
  zm: "Zambie",
  zw: "Zimbabwe"
};
export default countryTranslations;