const interfaceTranslations = {
  selectedCountryAriaLabel: "País seleccionado",
  noCountrySelected: "Ningún país seleccionado",
  countryListAriaLabel: "Lista de países",
  searchPlaceholder: "Buscar",
  zeroSearchResults: "No se han encontrado resultados",
  oneSearchResult: "1 resultado encontrado",
  multipleSearchResults: "${count} resultados encontrados",
  // additional countries (not supported by country-list library)
  ac: "isla Ascencion",
  xk: "Kosovo"
};
export default interfaceTranslations;