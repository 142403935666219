const countryTranslations = {
  ad: "Andorra",
  ae: "Arabiemiirikunnat",
  af: "Afganistan",
  ag: "Antigua ja Barbuda",
  ai: "Anguilla",
  al: "Albania",
  am: "Armenia",
  ao: "Angola",
  ar: "Argentiina",
  as: "Amerikan Samoa",
  at: "Itävalta",
  au: "Australia",
  aw: "Aruba",
  ax: "Ahvenanmaa",
  az: "Azerbaidžan",
  ba: "Bosnia ja Hertsegovina",
  bb: "Barbados",
  bd: "Bangladesh",
  be: "Belgia",
  bf: "Burkina Faso",
  bg: "Bulgaria",
  bh: "Bahrain",
  bi: "Burundi",
  bj: "Benin",
  bl: "Saint-Barthélemy",
  bm: "Bermuda",
  bn: "Brunei",
  bo: "Bolivia",
  bq: "Karibian Alankomaat",
  br: "Brasilia",
  bs: "Bahama",
  bt: "Bhutan",
  bw: "Botswana",
  by: "Valko-Venäjä",
  bz: "Belize",
  ca: "Kanada",
  cc: "Kookossaaret (Keelingsaaret)",
  cd: "Kongon demokraattinen tasavalta",
  cf: "Keski-Afrikan tasavalta",
  cg: "Kongon tasavalta",
  ch: "Sveitsi",
  ci: "Norsunluurannikko",
  ck: "Cookinsaaret",
  cl: "Chile",
  cm: "Kamerun",
  cn: "Kiina",
  co: "Kolumbia",
  cr: "Costa Rica",
  cu: "Kuuba",
  cv: "Kap Verde",
  cw: "Curaçao",
  cx: "Joulusaari",
  cy: "Kypros",
  cz: "Tšekki",
  de: "Saksa",
  dj: "Djibouti",
  dk: "Tanska",
  dm: "Dominica",
  do: "Dominikaaninen tasavalta",
  dz: "Algeria",
  ec: "Ecuador",
  ee: "Viro",
  eg: "Egypti",
  eh: "Länsi-Sahara",
  er: "Eritrea",
  es: "Espanja",
  et: "Etiopia",
  fi: "Suomi",
  fj: "Fidži",
  fk: "Falklandinsaaret",
  fm: "Mikronesian liittovaltio",
  fo: "Färsaaret",
  fr: "Ranska",
  ga: "Gabon",
  gb: "Iso-Britannia",
  gd: "Grenada",
  ge: "Georgia",
  gf: "Ranskan Guayana",
  gg: "Guernsey",
  gh: "Ghana",
  gi: "Gibraltar",
  gl: "Grönlanti",
  gm: "Gambia",
  gn: "Guinea",
  gp: "Guadeloupe",
  gq: "Päiväntasaajan Guinea",
  gr: "Kreikka",
  gt: "Guatemala",
  gu: "Guam",
  gw: "Guinea-Bissau",
  gy: "Guyana",
  hk: "Hongkong – Kiinan e.h.a.",
  hn: "Honduras",
  hr: "Kroatia",
  ht: "Haiti",
  hu: "Unkari",
  id: "Indonesia",
  ie: "Irlanti",
  il: "Israel",
  im: "Mansaari",
  in: "Intia",
  io: "Brittiläinen Intian valtameren alue",
  iq: "Irak",
  ir: "Iran",
  is: "Islanti",
  it: "Italia",
  je: "Jersey",
  jm: "Jamaika",
  jo: "Jordania",
  jp: "Japani",
  ke: "Kenia",
  kg: "Kirgisia",
  kh: "Kambodža",
  ki: "Kiribati",
  km: "Komorit",
  kn: "Saint Kitts ja Nevis",
  kp: "Pohjois-Korea",
  kr: "Etelä-Korea",
  kw: "Kuwait",
  ky: "Caymansaaret",
  kz: "Kazakstan",
  la: "Laos",
  lb: "Libanon",
  lc: "Saint Lucia",
  li: "Liechtenstein",
  lk: "Sri Lanka",
  lr: "Liberia",
  ls: "Lesotho",
  lt: "Liettua",
  lu: "Luxemburg",
  lv: "Latvia",
  ly: "Libya",
  ma: "Marokko",
  mc: "Monaco",
  md: "Moldova",
  me: "Montenegro",
  mf: "Saint-Martin",
  mg: "Madagaskar",
  mh: "Marshallinsaaret",
  mk: "Pohjois-Makedonia",
  ml: "Mali",
  mm: "Myanmar (Burma)",
  mn: "Mongolia",
  mo: "Macao – Kiinan e.h.a.",
  mp: "Pohjois-Mariaanit",
  mq: "Martinique",
  mr: "Mauritania",
  ms: "Montserrat",
  mt: "Malta",
  mu: "Mauritius",
  mv: "Malediivit",
  mw: "Malawi",
  mx: "Meksiko",
  my: "Malesia",
  mz: "Mosambik",
  na: "Namibia",
  nc: "Uusi-Kaledonia",
  ne: "Niger",
  nf: "Norfolkinsaari",
  ng: "Nigeria",
  ni: "Nicaragua",
  nl: "Alankomaat",
  no: "Norja",
  np: "Nepal",
  nr: "Nauru",
  nu: "Niue",
  nz: "Uusi-Seelanti",
  om: "Oman",
  pa: "Panama",
  pe: "Peru",
  pf: "Ranskan Polynesia",
  pg: "Papua-Uusi-Guinea",
  ph: "Filippiinit",
  pk: "Pakistan",
  pl: "Puola",
  pm: "Saint-Pierre ja Miquelon",
  pr: "Puerto Rico",
  ps: "Palestiinalaisalueet",
  pt: "Portugali",
  pw: "Palau",
  py: "Paraguay",
  qa: "Qatar",
  re: "Réunion",
  ro: "Romania",
  rs: "Serbia",
  ru: "Venäjä",
  rw: "Ruanda",
  sa: "Saudi-Arabia",
  sb: "Salomonsaaret",
  sc: "Seychellit",
  sd: "Sudan",
  se: "Ruotsi",
  sg: "Singapore",
  sh: "Saint Helena",
  si: "Slovenia",
  sj: "Huippuvuoret ja Jan Mayen",
  sk: "Slovakia",
  sl: "Sierra Leone",
  sm: "San Marino",
  sn: "Senegal",
  so: "Somalia",
  sr: "Suriname",
  ss: "Etelä-Sudan",
  st: "São Tomé ja Príncipe",
  sv: "El Salvador",
  sx: "Sint Maarten",
  sy: "Syyria",
  sz: "Swazimaa",
  tc: "Turks- ja Caicossaaret",
  td: "Tšad",
  tg: "Togo",
  th: "Thaimaa",
  tj: "Tadžikistan",
  tk: "Tokelau",
  tl: "Itä-Timor",
  tm: "Turkmenistan",
  tn: "Tunisia",
  to: "Tonga",
  tr: "Turkki",
  tt: "Trinidad ja Tobago",
  tv: "Tuvalu",
  tw: "Taiwan",
  tz: "Tansania",
  ua: "Ukraina",
  ug: "Uganda",
  us: "Yhdysvallat",
  uy: "Uruguay",
  uz: "Uzbekistan",
  va: "Vatikaani",
  vc: "Saint Vincent ja Grenadiinit",
  ve: "Venezuela",
  vg: "Brittiläiset Neitsytsaaret",
  vi: "Yhdysvaltain Neitsytsaaret",
  vn: "Vietnam",
  vu: "Vanuatu",
  wf: "Wallis ja Futuna",
  ws: "Samoa",
  ye: "Jemen",
  yt: "Mayotte",
  za: "Etelä-Afrikka",
  zm: "Sambia",
  zw: "Zimbabwe"
};
export default countryTranslations;