import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ToastComponent, ToastService } from '@pearsonvue/topaz-angular-ui';
import { TopazToastType } from '@pearsonvue/topaz-angular-ui/lib/components/toast/types/toast.type';
import { SchedulingErrorCodeService } from './scheduling-error-code.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private sessionService: SessionService,
    private toastService: ToastService, 
    private transloco: TranslocoService,
    private schedulingErrorCodeService: SchedulingErrorCodeService) { }
  
  resendConfirmatioEmail(sessionid: number) {
    this.sessionService.ResendCreateSessionEmails(sessionid).subscribe({
      next: response => {
        switch (response.emails.length) {
          case 1:
            this.showToast("", this.transloco.translate('scheduleTableOptions.resendConfirmationEmailCreatorSuessMessage', { creatorEmailaddress: response.emails[0] }), "success");
            break;
          case 2:
            this.showToast("", this.transloco.translate('scheduleTableOptions.resendConfirmationEmailCreatorAndProctorSuessMessage', { creatorEmailaddress: response.emails[0], proctorEmailaddress1: response.emails[1] }), "success");
            break;
        }
      }, error: (errorResponse) => {
        if (errorResponse?.error?.errorCode) {
          this.schedulingErrorCodeService.showErrorCodeBanner(errorResponse?.error?.errorCode, errorResponse?.error?.traceId);
        }
        else
          this.showToast(this.transloco.translate("genericErrorHeader"), this.transloco.translate("genericErrorContent"), "warn");
      }
    });
  }

  private showToast(toastHeader:string, toastContent: string, toastType: string) {
    this.toastService.open(ToastComponent, {
      toastType: toastType as TopazToastType,
      toastHeader: toastHeader,
      toastContent: toastContent
    });
  }
  
}
