<div id="select-exam-type-dialog-container">
  <div cdkTrapFocus
       aria-modal="true"
       role="dialog"
       [attr.aria-label]="  t.translate('dashboard.selectexamtype.header.selectsessiontype')"
       [attr.aria-describ]="  t.translate('dashboard.selectexamtype.header.selectsessiontype')"
       id="select-exam-type-dialog-container">
    <div class="topaz-dialog-header">
      <h3 id="modal-title">
        {{ t.translate('dashboard.selectexamtype.header.selectsessiontype') }}
      </h3>
      <button  mat-icon-button mat-dialog-close (click)="this.dialogRef.close()">
        <fa-icon id="mySelectExamCloseIcon" title="close" [icon]="icons.faXmark"></fa-icon>
      </button>
    </div>
    <div id="examtype-detail-cards" class="examtype-details-grid">

      <div id="examtype-type-panel" class="grid-col-span-2">
        <mat-card id="examtype-content" class="topaz-table-container exam-type-card">
          <div id="innerGrid" class="examtype-details-grid">
            <div class="grid-col-span-2">
              <mat-card id="imageCard" class="topaz-table-container exam-type-img-card">
                <img id="card-images" src="assets/images/CCB_select_img.png" alt="ccbSelectImg">
              </mat-card>
            </div>
            <div class="grid-col-span-2">
              <div id="box-body-text">
                <h2 id="card-header">
                  {{t.translate('dashboard.selectexamtype.ccb_cardbody_header')}}
                </h2>
                <div id="location-text">
                  {{t.translate('dashboard.selectexamtype.ccb_in_samelocation')}}
                </div>
                <div id="card-body-text">
                  {{t.translate('dashboard.selectexamtype.cardbody_recomend1')}}
                  <a href="https://certiport.pearsonvue.com/Support/Quick-reference-guides" target="_blank">{{t.translate('dashboard.selectexamtype.quickreferenceguide')}}</a>
                  {{t.translate('dashboard.selectexamtype.cardbody_recomend2')}}
                </div>
              </div>
              <div class="button" mat-dialog-actions [align]="'end'">
                <button id="select-type-button" mat-flat-button color="primary" class="topaz-button-small" (click)="onClick(SessionTypes.Local)">
                  {{t.translate('createsession.examdetails.select')}}
                </button>
              </div>
            </div>
          </div>
        </mat-card>
      </div>

      <div id="examtype-type-panel" class="grid-col-span-2">
        <mat-card id="examtype-content" class="topaz-table-container exam-type-card">
          <div id="innerGrid" class="examtype-details-grid">
            <div class="grid-col-span-2">
              <mat-card id="imageCard" class="topaz-table-container exam-type-img-card">
                <img id="card-images" src="assets/images/EFH_select_img.png" alt="efhSelectImg">
              </mat-card>
            </div>
            <div class="grid-col-span-2">
              <div id="box-body-text">
                <h2 id="card-header">
                  {{this.t.translate('dashboard.selectexamtype.efh_cardbody_header')}}
                </h2>
                <div id="location-text">
                  {{this.t.translate('dashboard.selectexamtype.efh_not_in_samelocation')}}
                </div>
                <div id="card-body-text">
                  {{this.t.translate('dashboard.selectexamtype.cardbody_recomend1')}}
                  <a href="https://certiport.pearsonvue.com/Support/Quick-reference-guides" target="_blank">{{t.translate('dashboard.selectexamtype.quickreferenceguide')}}</a>
                  {{this.t.translate('dashboard.selectexamtype.cardbody_recomend2')}}
                </div>
                <div id="note-text">
                  <b> {{this.t.translate('dashboard.selectexamtype.efh_card_note')}}</b>
                  {{this.t.translate('dashboard.selectexamtype.efh_card_notetext')}}
                </div>
              </div>
              <div class="button" mat-dialog-actions [align]="'end'">
                <button id="select-type-button" mat-flat-button color="primary" class="topaz-button-small" (click)="onClick(SessionTypes.Remote)">
                  {{t.translate('createsession.examdetails.select')}}
                </button>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
