<div class="topaz-page-content" role="main" *ngIf="settingsLoaded">
  <topaz-banner-root></topaz-banner-root>
  <div *transloco="let t;">
    <div class="section-title">
      <div class="desktop">
          <h1 autofocus>{{ t('session.dashboard') }}</h1>
      </div>
      <div class="buttons desktop">
        <button mat-flat-button color="primary" class="mb-btn-createsession" (click)="onSelectSessionType()">{{ t('nextSessionTableButton.createSession') }}</button>
      </div>
    </div>
    <app-next-session [nextSessionData]="nextSessionDataSource" (startSession)="handleStartSession($event)"></app-next-session>
    <div class="dashboard-seesion-schedule-container">
      <div role="region" aria-labelledby="schedule-list">
        <h3 id="schedule-list" class="title-h3"> {{ t('scheduleTable.title') }}</h3>
        <mat-card class="topaz-table-container">
          <app-session-schedule-table
            [sessionScheduleData]="sessionScheduleDataSource"
            [noDataText]="t('scheduleTable.noSessionsScheduled')">
          </app-session-schedule-table>
        </mat-card>
      </div>
    </div>
  </div>
</div>
