import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from './config-service';
import { UserService } from 'certiport-layout-library';
import { InvokeSessionDetails, SessionInvokeType, SharedStateService } from './shared-state.service';
import { AuthenticationStateService, generateLoginRedirect, UserDetailService } from '@certiport/login-library';
import { ISessionActionParam } from '../models/session-action-param';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private window: Window,
    private route: ActivatedRoute,
    private userService : UserService,
    private configService: ConfigService,
    private userDetailService: UserDetailService,
    private sharedStateService: SharedStateService,
    private authenticationStateService: AuthenticationStateService,
  ) { }


  redirectToIESLogin(params: string = '') {
    const baseLoginUri = this.configService.iesBaseLoginUri;
    const subLoginUri = this.configService.iesSubLoginUri;
    const ssoUri = this.configService.iesSSOUri;
    let returnUrl = this.configService.iesReturnUri;

    if (params){
      returnUrl = `${returnUrl}?t=${params}`;
    }

    this.window.location.href = generateLoginRedirect(
      baseLoginUri,
      subLoginUri,
      ssoUri,
      returnUrl || '',
    );
  }

  afterSucessfulIESAuthentication() {
    const user =  this.authenticationStateService.state.value;

    this.userDetailService.setUserDetail({
      userName: user.UserDisplayName!,
      userId: user.UserId ?? 0,
      userRoles: user.UserRoles!,
      loginExpiry: new Date(user.LoginExpiry!)
    });

    this.userService.setUserName(user.UserDisplayName!);

    this.setSessionAction();
  }

  private setSessionAction() {
    this.route.queryParams.subscribe(p => {

      const params = p['t'];

      if (!params) return;

      const sessionActionParam : ISessionActionParam = JSON.parse(params);
      
      const action = sessionActionParam.action;
      const code = sessionActionParam.code;

      let sessionInvokeType : SessionInvokeType = SessionInvokeType.ViewSession;

      switch (action) {
        case 'edit':
          sessionInvokeType = SessionInvokeType.EditSession;
          break;
      }

      const invokeSessionDetails: InvokeSessionDetails = { invokeSessionData: true, invokeType: sessionInvokeType, sessionId: 0, sessionCode: code};

      this.sharedStateService.setInvokeSessionData(invokeSessionDetails);
    });
  }

  setUserName() {
    const userDetails = this.userDetailService.getUserDetail();
    this.userService.setUserName(userDetails?.userName);
  }
}
