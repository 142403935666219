const TOAST_TIMEOUT = 5000;
const SESSION_STATUS_CONFIRMED_ID = 3;

enum DateFormats {
  MM_DD_YYYY = 'MM/dd/yyyy',
  MONTH_DATE_TIME_TIMEZONE = 'MMMM dd, yyyy h:mm a OOOO',
}

export {
  TOAST_TIMEOUT,
  DateFormats,
  SESSION_STATUS_CONFIRMED_ID
}

export const SessionTypes = { 
  Local: 'Local',
  Remote: 'Remote'
} as const;
export type SessionType = keyof typeof SessionTypes;
